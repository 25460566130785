/* eslint-disable no-useless-escape */
const licensePattern = '^[\. A-Za-z0-9-+]{1,}$';

// based on https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s02.html
const mobileNumberPattern = '^[\\(]?([2-9][0-8][0-9])[\\)]?[-. ]?([2-9][0-9]{2})[-. ]?[0-9]{4}$';

// based on https://stackoverflow.com/questions/22993545/ruby-email-validation-with-regex
const emailPattern = '^[\\w+\\-.]+@[a-z\\d\\-]+(\\.[a-z\\d\\-]+)*\\.[a-z]+$';

const urlPattern = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

const legUpUrlPattern = '^(https:\/\/directory.legup.care\/childcare\/)(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$';

const profilePhoneNumberPattern = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/gm;

// test to match `h:mm a` time format, ie: 7:00 am or 10:00 pm
const hmmaTimePattern = /^(1[012]|[1-9]):([0-5][0-9]) ([ap][m])$/;

const hmmaTime = {
  pattern: hmmaTimePattern,
  regex: new RegExp(hmmaTimePattern),
};

const license = {
  pattern: licensePattern,
  regex: new RegExp(licensePattern),
};

const mobileNumber = {
  pattern: mobileNumberPattern,
  regex: new RegExp(mobileNumberPattern),
};

const email = {
  pattern: emailPattern,
  regex: new RegExp(emailPattern, 'i'),
};

const url = {
  pattern: urlPattern,
  regex: new RegExp(urlPattern),
};

const legUpUrl = {
  pattern: legUpUrlPattern,
  regex: new RegExp(legUpUrlPattern),
};

const profilePhoneNumber = {
  pattern: profilePhoneNumberPattern,
  regex: new RegExp(profilePhoneNumberPattern),
};

export default {
  license,
  mobileNumber,
  email,
  url,
  profilePhoneNumber,
  legUpUrl,
  hmmaTime,
};
