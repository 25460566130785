import { getDollarFormat } from './dollarFormat';
import getStringFromArray from './getStringFromArray';

function age(value = {}, defaults = {}, prefixes = {}, depKey = 'nameAge') {
  if (value.dependents?.length) {
    const prefix = prefixes.dependents || '';
    const namesArr = value.dependents
      .map((id) => defaults.dependents[id] && defaults.dependents[id][depKey])
      .filter(Boolean);

    return `${prefix}${getStringFromArray(namesArr)}`;
  }

  return undefined;
}

function location({ searchRadius, targetAddress }, searchWhenMapMove) {
  return searchWhenMapMove
    ? 'In the selected map area'
    : `Within ${searchRadius} miles of ${targetAddress}`;
}

function startDate({ value, date }, moment) {
  if (value === 'immediate') {
    return 'Starting immediately';
  } if (value === 'future') {
    const dateString = moment(date).format('MMM DD, YYYY');
    return `Starting on ${dateString}`;
  }
  return 'Start date is flexible';
}

function budget(priceMax) {
  return `Up to ${getDollarFormat(priceMax)} per month`;
}

function schedule(scheduleNeeds, dependents) {
  if (!scheduleNeeds || scheduleNeeds.length === 0) {
    return undefined;
  }

  const daysPerWeek = [];
  for (let i = 0; i < scheduleNeeds.length; i += 1) {
    const scheduleNeed = scheduleNeeds[i];
    const checkedDays = scheduleNeed.days.filter((day) => day.checked).length;

    if (dependents[scheduleNeed.id]) {
      daysPerWeek.push(`${checkedDays} days for ${dependents[scheduleNeed.id].firstName}`);
    }
  }

  if (daysPerWeek.length === 0) {
    return undefined;
  }

  return daysPerWeek.join(', ');
}

function facilityClass(fClass) {
  switch (fClass) {
    case 'homebased':
      return 'Open to home based';
    case 'center':
      return 'Open to centers';
    default:
      return 'Open to home based or centers';
  }
}

function philosophies(value = [], list = [], start = 'Preferrably') {
  if (!value.length) {
    return undefined;
  }
  const strings = value
    .map((v) => list.find(({ id }) => id === v))
    .filter(Boolean)
    .map((v) => v.name);

  return `${start} ${getStringFromArray(strings, { lastJoiner: ' or ' }).toLowerCase()}.`;
}

export default {
  age,
  location,
  startDate,
  budget,
  facilityClass,
  philosophies,
  schedule,
};
